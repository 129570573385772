import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import CTALink from '../CTALink';

const PrefooterCTA = ({ pageBody: { cta } }) => {
  return cta?.link?.showLink ? (
    <Box sx={prefooterCtaStyles}>
      <CTALink linkData={cta} showUnderline={false} reverseIcon />{' '}
    </Box>
  ) : null;
};

PrefooterCTA.defaultProps = {
  cta: PropTypes.object,
};

export default PrefooterCTA;

const prefooterCtaStyles = {
  py: [5, null, 6],
  textAlign: 'center',
};
